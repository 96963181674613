/* eslint-disable */
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
var TRANSITION_DELAY = 150;
var TRANSITION_DOWN_DURATION = 200;

exports.TRANSITION_DELAY = TRANSITION_DELAY;
exports.TRANSITION_DOWN_DURATION = TRANSITION_DOWN_DURATION;
